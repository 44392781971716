import React from "react";
import { Link } from "gatsby";
import linkHelper from "../helper/linkHelper";
import Image from "./image";
import arrow from "../img/diagonal-black.svg";

/** 
- Testimonial module consisting of an image, name, location, quote, related article and text.
**/

const Testimonial = ({
  testimonial,
  name,
  quote,
  location,
  linkAddress,
  linkText,
  image,
}) => {
  if (!testimonial) {
    testimonial = {
      name,
      quote,
      location,
      linkAddress,
      linkText,
      image,
    };
  }
  const imageOptions = {
    width: 375,
    height: 500,
    aspectRatio: 3 / 4,
  };
  const linkObject = linkHelper(testimonial.linkAddress);
  return (
    testimonial.quote &&
    testimonial.image && (
      <div className="testimonial">
        <div className="testimonial-image-container">
          <Image
            image={testimonial.image}
            alt={testimonial.image.altText || ""}
            options={imageOptions}
          />
          <div className="swiper-buttons -mobile">
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </div>
        </div>

        <div className="testimonial-content-container">
          {(testimonial.name || testimonial.location) && (
            <p className="testimonial-caption caption">
              {testimonial.name && testimonial.name}
              {testimonial.name && testimonial.location && " | "}
              {testimonial.location && testimonial.location}
            </p>
          )}
          <blockquote className="testimonial-quote subtitle-one">
            {`${testimonial.quote}`}
          </blockquote>
          {linkObject?.link && testimonial.linkText && (
            <React.Fragment>
              {linkObject.type === "external" && (
                <a
                  className="testimonial-link subtitle-two add-underline"
                  href={linkObject.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {testimonial.linkText || "Read the full story"}
                  <img src={arrow} alt="" />
                </a>
              )}
              {linkObject.type === "internal" && (
                <Link
                  className="testimonial-link subtitle-two add-underline"
                  to={linkObject.link}
                >
                  {testimonial.linkText || "Read the full story"}
                  <img src={arrow} alt="" />
                </Link>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    )
  );
};

export default Testimonial;
