const eventLocations = locations => {
  if (!locations || locations.length === 0) {
    return locations;
  } else if (locations.length === 9) {
    return "National & Online";
  } else if (locations.length === 8 && !locations.includes("Online")) {
    return "National";
  } else if (locations.includes("Online")) {
    if (locations.length > 1) {
      let locationsArray = locations.filter(e => e !== "Online");
      return `${locationsArray.join(", ")} & Online`;
    } else {
      return "Online";
    }
  } else {
    return locations.join(", ");
  }
};

export default eventLocations;
