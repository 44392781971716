import React from "react";
import dateToString from "../helper/dateToString";
import BasicImage from "./basic-image";
import truncate from "../helper/truncate";

/** 
- Featured article module visible on the home page
- The module consists of an image, title, published date, tags array, and and slug.
**/

const RecentArticle = ({
  recentArticle,
  title,
  slug,
  publishedDate,
  tags,
  image,
  path,
  tileDescription,
}) => {
  if (!recentArticle) {
    recentArticle = {
      title,
      slug,
      publishedDate,
      tags,
      image,
      path,
      tileDescription,
    };
  }
  return (
    recentArticle.title &&
    recentArticle.slug && (
      <div className="recent-article-container">
        <a
          className="recent-article-link general-hover"
          href={`/news/${recentArticle.slug.current}`}
        >
          <div className="recent-article-image">
            <div className="e-image-container e-three-four-container">
              <BasicImage
                image={recentArticle?.image?.image}
                placeholder="news"
              />
            </div>
          </div>
          <p className="overline">
            {recentArticle.publishedDate &&
              dateToString(recentArticle.publishedDate)}

            {recentArticle.tags[0] &&
              recentArticle.tags.map((tag, i) => {
                return <span key={i}>{` ${tag.value}`}</span>;
              })}
          </p>
          <p className="recent-article-title subtitle-two">
            {recentArticle.title}
          </p>
          {recentArticle.tileDescription && (
            <p className="recent-article-excerpt">
              {truncate(recentArticle.tileDescription, 150)}
            </p>
          )}
        </a>
      </div>
    )
  );
};

export default RecentArticle;
