import React from "react";
import dateToString from "../helper/dateToString";
import Image from "../components/image";
import truncate from "../helper/truncate";
import { format } from "date-fns";
import courseCategory from "../helper/courseCategory";
import eventLocations from "../helper/eventLocations";
import { Link } from "gatsby";

/** 
- Content Tile module with variations for dark, light and accent colour schemes.
- Content types can be events, articles or courses
**/

const ContentTile = ({
  content,
  title,
  slug,
  image,
  tileDescription,
  _type,
  date,
  publishedDate,
  memberCost,
  nonMemberCost,
  tags,
  _rawBody,
  type,
}) => {
  if (!content) {
    content = {
      title,
      slug,
      image: {
        image,
      },
      tileDescription,
      _type,
      date,
      publishedDate,
      memberCost,
      nonMemberCost,
      tags,
      _rawBody,
    };
  }

  let dateValue, imageObject, contentType;
  if (content._type === "news") {
    dateValue = dateToString(content.publishedDate);
    contentType = "news";
    if (content.image && content.image.image) {
      imageObject = content.image.image;
    }
  } else if (content._type === "events") {
    imageObject = content.image;
    contentType = courseCategory(content.tags);
    if (content.startDate === content.endDate) {
      dateValue = format(new Date(content.endDate), "dd.MM.yyyy") + " ";
    } else {
      dateValue = `${format(
        new Date(content.startDate),
        "dd.MM.yyyy"
      )} - ${format(new Date(content.endDate), "dd.MM.yyyy")} `;
    }
  }
  const imageOptions = {
    width: 268,
    height: 228,
    aspectRatio: 3 / 4,
  };
  let tagsArray = [];
  if (content.tags?.[0]) {
    tagsArray = content.tags.map(elem => {
      return elem.value;
    });
  }
  return (
    content.title &&
    content.slug && (
      <Link
        to={content.path}
        className={`single-content-tile-container ${type ? "-" + type : ""}`}
      >
        <div className="content-tile-image-container">
          <Image
            image={imageObject}
            alt={imageObject ? imageObject.altText : content.title}
            options={imageOptions}
            placeholder={contentType}
          />
        </div>
        <div className="content-tile-content-container">
          <div className="content-tile-inner-heading">
            <p className="overline">
              {dateValue}
              {tagsArray && tagsArray?.[0] && (
                <span>{tagsArray.join(", ")}</span>
              )}

              {content.location && (
                <span>{eventLocations(content.location)} </span>
              )}
            </p>
            <div className="content-tile-name">
              <h3 className="content-tile-name subtitle-two">
                {content.title}
              </h3>
            </div>
          </div>

          {content.tileDescription && (
            <p className="content-tile-copy">
              {truncate(content.tileDescription, 150)}
            </p>
          )}
        </div>
      </Link>
    )
  );
};

export default ContentTile;
