import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";

import Testimonial from "./testimonial";

/** 
- Post spotlight module, used on the home page
**/

SwiperCore.use([Autoplay, Navigation, Pagination]);

const params = {
  slidesPerView: 1,
  pagination: { clickable: true },
  watchOverflow: true,
  autoplay: {
    delay: 4000,
    disableOnInteraction: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  speed: 800,
};

const MemberStories = ({ stories }) => {
  return (
    <Swiper {...params}>
      {stories &&
        stories[0] &&
        stories.map((story, i) => {
          return (
            <SwiperSlide key={i}>
              <Testimonial testimonial={story} />
            </SwiperSlide>
          );
        })}
      <div className="swiper-buttons -desktop">
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </div>
    </Swiper>
  );
};

export default MemberStories;
