const courseCategory = tags => {
  if (tags && tags[0]) {
    if (tags.filter(tag => tag.value === "Course").length > 0) {
      return "course";
    } else {
      return "event";
    }
  } else {
    return "event";
  }
};

export default courseCategory;
