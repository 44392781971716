import React from "react";
import Button from "./button";
import Image from "./image";

const Banner = ({
  title,
  intro,
  alignment = "left",
  buttonText,
  buttonLink,
  image,
  type,
}) => {
  const height = 260;
  const width = 580;
  const aspectRatio = 2 / 1;
  const imageOptions = {
    width,
    height,
    aspectRatio,
  };
  alignment = alignment === "Left" ? false : true;
  return (
    //Alignment is center alignment otherwise underfined
    <section
      className={`banner${type ? " -" + type : ""}${
        alignment ? " -large" : ""
      }`}
    >
      <div className="inner-wrapper">
        <div className={`banner-inner${alignment ? " -center" : ""}`}>
          <div
            className={`banner-text-content${
              image || (buttonLink && buttonText && !alignment) ? " -split" : ""
            }`}
          >
            {title && (
              <h2
                className={`banner-title ${alignment ? "h1" : "subtitle-one"}`}
              >
                {title}
              </h2>
            )}

            {/* if banner is not center aligned */}
            {intro && !alignment && <p className="banner-intro">{intro}</p>}

            {/* if banner is centre aligned */}
            {intro && alignment && (
              <p className="banner-intro-accent subtitle-one">{intro}</p>
            )}

            {/* Variations where button is left aligned (if there is no image) */}
            {image && buttonLink && buttonLink && (
              <Button
                type={type === "ghost" ? "secondary" : "primary"}
                button={{
                  link: buttonLink,
                  text: buttonText,
                }}
              />
            )}
          </div>

          {(image || (buttonLink && buttonText)) && (
            <div className="banner-side-container">
              {image && (
                <Image
                  image={image}
                  alt={image.asset?.altText || ""}
                  options={imageOptions}
                />
              )}
              {buttonLink && buttonText && !image && (
                <Button
                  type={type === "ghost" ? "secondary" : "primary"}
                  button={{
                    link: buttonLink,
                    text: buttonText,
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Banner;
