import React from "react";
import Button from "./button";
import ContentTile from "./content-tile";
import { Swiper, SwiperSlide } from "swiper/react";

/** 
- Post spotlight module, used on the home page
**/

const PostSpotlight = ({ spotlight }) => {
  const params = {
    freeMode: true,
    slidesPerView: 1,
    spaceBetween: 16,
    breakpoints: {
      730: {
        slidesPerView: 2,
      },
      500: {
        slidesPerView: 1.5,
      },
      420: {
        slidesPerView: 1.25,
      },
    },
  };
  return (
    <section className={`post-spotlight -${spotlight.type}`}>
      <div className="spotlight-inner">
        <div className="spotlight-content">
          {spotlight.surtitle && (
            <p className="spotlight-surtitle ">{spotlight.surtitle}</p>
          )}
          {spotlight.title && (
            <h2 className="spotlight-title">{spotlight.title}</h2>
          )}
          {spotlight.buttonText && spotlight.buttonLink && (
            <div className="flex-button-container -desktop">
              <Button
                type={spotlight.type[0]}
                button={{
                  link: spotlight.buttonLink,
                  text: spotlight.buttonText,
                }}
              />
            </div>
          )}
        </div>
        <div className="tile-container">
          <p className="spotlight-content-surtitle">{spotlight.postSurtitle}</p>
          <div className="tile-wrapper -desktop">
            {spotlight.referencedPosts[0] &&
              spotlight.referencedPosts.map((content, i) => {
                return (
                  <ContentTile
                    content={content}
                    type={spotlight.type}
                    key={i}
                  />
                );
              })}
          </div>
          <div className="tile-wrapper -mobile">
            <Swiper {...params}>
              {spotlight.referencedPosts[0] &&
                spotlight.referencedPosts.map((content, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <ContentTile content={content} type={spotlight.type} />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
        {spotlight.buttonText && spotlight.buttonLink && (
          <div className="flex-button-container -mobile">
            <Button
              type={spotlight.type[0]}
              button={{
                link: spotlight.buttonLink,
                text: spotlight.buttonText,
              }}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default PostSpotlight;
