import React from "react";

import AccordionModule from "./accordion-module";
import ContentTile from "./content-tile";
import Banner from "./new-banner";
import PostSpotlight from "./post-spotlight";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../helper/textSerializer";
import FeaturedArticle from "./home-featured-article";
import RecentArticle from "./recent-article";
import Button from "./button";
import MemberStories from "./member-stories";
import HomeHero from "./home-hero";

const HomeBuilder = ({ pagebuilder, articles, type }) => {
  return (
    pagebuilder[0] &&
    pagebuilder.map((item, i) => {
      if (item._type === "contentTileModule") {
        return (
          <section className="content-tile-container" key={i}>
            {item.heading && <h2 className="module-heading">{item.heading}</h2>}
            <div className={`content-tile-wrapper -${item.columns}`}>
              {item.items.map((content, j) => {
                return <ContentTile content={content} key={j} />;
              })}
            </div>
          </section>
        );
      } else if (item._type === "accordionModule") {
        return <AccordionModule key={i} accordion={item} />;
      } else if (item._type === "homeBanner") {
        return <HomeHero {...item} key={i} />;
      } else if (item._type === "smallBanner") {
        return <Banner {...item} key={i} />;
      } else if (item._type === "contentBlockArray") {
        return (
          <section className="content-blocks" key={i}>
            {item.heading && <h2 className="module-heading">{item.heading}</h2>}
            <div className="content-blocks-inner">
              {item.block.map((block, i) => {
                return (
                  block._rawBlock &&
                  block._rawBlock[0] && (
                    <div className="single-content-block" key={i}>
                      <BlockContent
                        blocks={block._rawBlock}
                        className="serialized-content"
                        renderContainerOnSingleChild={true}
                        serializers={{
                          types: {
                            block: serializers.types.block,
                            undefined: serializers.undefined,
                            image: serializers.image,
                            articleImage: serializers.articleImage,
                            youtube: serializers.youtube,
                            vimeo: serializers.vimeo,
                            articleReference: serializers.articleReference,
                            news: serializers.articleReference,
                            button: serializers.button,
                            longLink: serializers.longLink,
                            mrec: serializers.mrec,
                            table: serializers.table,
                          },
                          hardBreak: serializers.hardBreak,
                          list: serializers.list,
                          listItem: serializers.listItem,
                          marks: serializers.marks,
                        }}
                      />
                    </div>
                  )
                );
              })}
            </div>
          </section>
        );
      } else if (item._type === "homePosts") {
        return <PostSpotlight key={i} spotlight={item} />;
      } else if (item._type === "featuredArticles" && item.include) {
        return (
          <section className="article-spotlight" key={i}>
            <h2 className="article-spotlight-heading">Recent News</h2>
            <FeaturedArticle featuredArticle={articles[0].node} />
            <div className="recent-articles">
              {articles.map((article, j) => {
                if (j === 0) {
                  return "";
                } else {
                  return <RecentArticle recentArticle={article.node} key={j} />;
                }
              })}
            </div>
            {item.buttonText && item.buttonLink && (
              <Button
                type="secondary"
                button={{
                  link: item.buttonLink,
                  text: item.buttonText,
                }}
              />
            )}
          </section>
        );
      } else if (item._type === "memberStoriesModule") {
        return (
          <section key={i} className="member-stories">
            <div className="member-stories-inner">
              {item.heading && (
                <h2 className="module-heading member-stories-heading">
                  {item.heading}
                </h2>
              )}
              <MemberStories stories={item.items} />
            </div>
          </section>
        );
      } else {
        return "";
      }
    })
  );
};
export default HomeBuilder;
