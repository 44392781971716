import React from "react";
import dateToString from "../helper/dateToString";
import BasicImage from "./basic-image";
import truncate from "../helper/truncate";

/**
- Featured article module visible on the home page
- The module consists of an image, title, published date, tags array, and and slug.
**/

const FeaturedArticle = ({
  featuredArticle,
  title,
  slug,
  image,
  publishedDate,
  tags,
}) => {
  if (!featuredArticle) {
    featuredArticle = {
      title,
      slug,
      image,
      publishedDate,
      tags,
    };
  }
  return (
    featuredArticle.title &&
    featuredArticle.image &&
    featuredArticle.slug && (
      <div className="featured-article">
        <div className="featured-article-content-container">
          <a
            className="featured-article-link add-underline"
            href={`/news/${featuredArticle.slug.current}`}
          >
            <p className="overline">
              {featuredArticle.publishedDate &&
                `${dateToString(featuredArticle.publishedDate)} `}
              {featuredArticle.tags[0] && (
                <span>{featuredArticle.tags[0].value}</span>
              )}
              {featuredArticle.tags[1] && (
                <span>{` ${featuredArticle.tags[1].value}`}</span>
              )}
            </p>
            <h3 className="featured-article-title">{featuredArticle.title}</h3>
            {featuredArticle.tileDescription && (
              <p className="featured-article-excerpt">
                {truncate(featuredArticle.tileDescription, 150)}
              </p>
            )}
          </a>
        </div>
        <div className="featured-article-image-container">
          <div className="e-image-container e-three-four-container">
            <BasicImage
              image={featuredArticle.image.image}
              placeholder="news"
            />
          </div>
        </div>
      </div>
    )
  );
};

export default FeaturedArticle;
