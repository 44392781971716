import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import HomeBuilder from "../components/home-builder";

export const query = graphql`
  query HomePageQuery {
    page: allSanityHomePage(
      filter: { slug: { current: { eq: "home-page" } } }
    ) {
      edges {
        node {
          slug {
            current
          }
          title
          id
          pageSEO {
            metaDescription
            metaTitle
            metaImage {
              asset {
                _id
                altText
              }
              hotspot {
                height
                width
                x
                y
              }
            }
          }
          homeBuilder {
            ... on SanitySmallBanner {
              _key
              _type
              ...smallBannerButtonLink
              type
              alignment
              buttonText
              intro
              title
              image {
                asset {
                  _id
                  altText
                }
              }
            }
            ... on SanityMemberStoriesModule {
              _key
              _type
              heading
              items {
                quote
                name
                location
                linkText
                linkAddress {
                  landingPageRoute {
                    ... on SanityPage {
                      id
                      slug {
                        current
                      }
                      path
                    }
                    ... on SanityNewsPage {
                      id
                      _type
                      slug {
                        current
                      }
                    }
                    ... on SanityNews {
                      id
                      _type
                      slug {
                        current
                      }
                    }
                    ... on SanityEventsPage {
                      id
                      _type
                      slug {
                        current
                      }
                    }
                    ... on SanityContactPage {
                      id
                      _type
                      slug {
                        current
                      }
                    }
                    ... on SanityEvents {
                      id
                      _type
                      slug {
                        current
                      }
                    }
                  }
                  link
                  route
                  pdf {
                    asset {
                      url
                    }
                  }
                }
                image {
                  _key
                  _type
                  asset {
                    _id
                    altText
                  }
                }
              }
            }
            ... on SanityHomePosts {
              _key
              _type
              buttonText
              postSurtitle
              type
              buttonLink {
                link
                pdf {
                  asset {
                    url
                  }
                }
                route
                landingPageRoute {
                  ... on SanityPage {
                    id
                    slug {
                      current
                    }
                    path
                  }
                  ... on SanityNewsPage {
                    slug {
                      current
                    }
                  }
                  ... on SanityNews {
                    slug {
                      current
                    }
                  }
                  ... on SanityEventsPage {
                    slug {
                      current
                    }
                  }
                  ... on SanityContactPage {
                    id
                    _type
                    slug {
                      current
                    }
                  }
                  ... on SanityEvents {
                    id
                    slug {
                      current
                    }
                  }
                }
              }
              referencedPosts {
                ... on SanityEvents {
                  id
                  _type
                  title
                  details {
                    costHeading
                    costDescription
                    costValue
                  }
                  tags {
                    value
                  }
                  slug {
                    current
                  }
                  path
                  tileDescription
                  startDate
                  endDate
                  image {
                    asset {
                      _id
                      altText
                    }
                  }
                }
                ... on SanityNews {
                  id
                  _type
                  title
                  tags {
                    value
                  }
                  slug {
                    current
                  }
                  path
                  tileDescription
                  image {
                    image {
                      asset {
                        _id
                        altText
                      }
                    }
                  }
                  publishedDate
                }
              }
              surtitle
              title
            }
            ... on SanityHomeBanner {
              _key
              _type
              surtitle
              title
              intro
              buttonText
              ...homeBannerButtonLink
            }
            ... on SanityFeaturedArticles {
              _type
              include
              buttonText
              ...featuredArticlesButtonLink
            }
            ... on SanityContentTileModule {
              _key
              _type
              columns
              heading
              items {
                ... on SanityEvents {
                  id
                  _type
                  title
                  details {
                    costHeading
                    costDescription
                    costValue
                  }
                  tags {
                    value
                  }
                  slug {
                    current
                  }
                  path
                  # _rawBody
                  tileDescription
                  startDate
                  endDate
                  image {
                    asset {
                      _id
                      altText
                    }
                  }
                }
                ... on SanityNews {
                  id
                  _type
                  title
                  tags {
                    value
                  }
                  slug {
                    current
                  }
                  path
                  _rawContent
                  image {
                    image {
                      asset {
                        _id
                        altText
                      }
                    }
                  }
                  publishedDate
                }
              }
            }
            ... on SanityContentBlockArray {
              _type
              heading
              block {
                _rawBlock(resolveReferences: { maxDepth: 8 })
              }
            }
            ... on SanityAccordionModule {
              _key
              _type
              heading
              items {
                heading
                _rawBody(resolveReferences: { maxDepth: 8 })
              }
              buttonText
              ...accordionModuleButtonLink
            }
          }
        }
      }
    }
    articles: allSanityNews(
      limit: 4
      filter: { slug: { current: { ne: null } } }

      sort: { order: DESC, fields: publishedDate }
    ) {
      edges {
        node {
          title
          publishedDate
          tags {
            value
          }
          tileDescription
          slug {
            current
          }
          image {
            image {
              asset {
                _id
                altText
              }
            }
          }
        }
      }
    }
  }
`;

const Index = props => {
  const { data } = props;
  const page = data.page.edges[0].node;
  return (
    <Layout className="home">
      <SEO title={page.title} seo={page.pageSEO} />
      {page.homeBuilder[0] && (
        <HomeBuilder
          pagebuilder={page.homeBuilder}
          articles={data.articles.edges}
        />
      )}
    </Layout>
  );
};

export default Index;
